/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import image26 from '../../images/images-sinais-pg/26.webp';
import image28 from '../../images/images-sinais-pg/28.webp';
import image09 from '../../images/images-sinais-pg/09.webp';
import image99 from '../../images/images-sinais-pg/1-novo.png';
import image100 from '../../images/images-sinais-pg/100.jpg';
import image101 from '../../images/images-sinais-pg/2-novo.png';
import image102 from '../../images/images-sinais-pg/3-novo.webp';
import image103 from '../../images/images-sinais-pg/103.webp';
import image104 from '../../images/images-sinais-pg/104.jpeg';
import image105 from '../../images/images-sinais-pg/105.jpeg';
import image106 from '../../images/images-sinais-pg/106.jpeg';
import image107 from '../../images/images-sinais-pg/107.jpeg';
import image108 from '../../images/images-sinais-pg/108.jpeg';
import image109 from '../../images/images-sinais-pg/109.jpeg';
import image110 from '../../images/images-sinais-pg/110.jpeg';
import image111 from '../../images/images-sinais-pg/111.jpeg';
import image112 from '../../images/images-sinais-pg/112.webp';
import image113 from '../../images/images-sinais-pg/113.jpg';
import image114 from '../../images/images-sinais-pg/114.jpg';
import image115 from '../../images/images-sinais-pg/115.webp';
import image116 from '../../images/images-sinais-pg/116.webp';
import image117 from '../../images/images-sinais-pg/117.webp';
import jogarImg from '../../images/jogar-img.png';

import image282 from '../../images/images-sinais-pg/28-2.avif';
import image1112 from '../../images/images-sinais-pg/111-2.avif';
import image1002 from '../../images/images-sinais-pg/100-2.avif';
import image092 from '../../images/images-sinais-pg/09-2.avif';
import image262 from '../../images/images-sinais-pg/26-2.avif';
import image1162 from '../../images/images-sinais-pg/116-2.avif';
import t1 from '../../images/images-sinais-pg/t1.avif';
import t2 from '../../images/images-sinais-pg/t2.jpg';
import t3 from '../../images/images-sinais-pg/t3.jpg';
import t4 from '../../images/images-sinais-pg/t4.jpg';
import t5 from '../../images/images-sinais-pg/t5.jpg';
import t6 from '../../images/images-sinais-pg/t6.jpg';

import AppContext from '../../context/AppContext';

const importImages = () => {
  const images = {};
  for (let i = 1; i <= 95; i++) {
    if (![26, 28, 9].some((e) => e === i)) {
      const imageName = i.toString().padStart(2, '0');
      images[`image${imageName}`] = require(`../../images/images-sinais-pg/${imageName}.webp`);
    }
  }
  return images;
};

const images = importImages();

export const imagesTwoo = [
  // image28,
  image282,
  // image111,
  image1112,
  // image100,
  image1002,
  // image26,
  image262,
  // image09,
  image092,
  // image116,
  image1162,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  image99,
  image101,
  image102,
  image103,
  image104,
  image105,
  image106,
  image107,
  image108,
  image109,
  image110,
  image112,
  image113,
  image114,
  image115,
  image117, ...Object.values(images)];

export default function ImagesArrPg() {
  const { jogos, horario, linkSite } = useContext(AppContext);
  const [imagemAtiva, setImagemAtiva] = useState(null);
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={ handleClose }
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const teste = (number) => {
    if (number >= 70) {
      return (
        <div id="teste" className="progress lg:w-40 w-28" style={ { height: '25px', fontSize: '20px', position: 'relative' } } role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div id="teste" className="progress-bar progress-bar-striped progress-bar-animated bg-success btn-game" style={ { width: `${number}%` } } />
          <p id="teste" className="fixed btn-game" style={ { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>{`${number}%`}</p>
        </div>
      );
    } if (number < 70 && number > 30) {
      return (
        <div id="teste" className="progress lg:w-40 w-28" style={ { height: '25px', fontSize: '20px', position: 'relative' } } role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div id="teste" className="progress-bar progress-bar-striped progress-bar-animated bg-warning btn-game" style={ { width: `${number}%` } } />
          <p id="teste" className="fixed btn-game" style={ { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>{`${number}%`}</p>
        </div>
      );
    }
    return (
      <div id="teste" className="progress lg:w-40 w-28" style={ { height: '25px', fontSize: '20px', position: 'relative' } } role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
        <div id="teste" className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={ { width: `${number}%` } } />
        <p id="teste" className="fixed btn-game" style={ { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>{`${number}%`}</p>
      </div>
    );
  };

  const testeTwo = (numberOne, numberTwo) => {
    if (numberOne > numberTwo) {
      return (
        <div id="teste" className="flex flex-col">
          <p id="teste" className="text-center text-white m-0 btn-game">{`Minutos pagante: ${numberTwo}/${numberOne} `}</p>
          <p id="teste" className="text-center text-white m-0 btn-game">{`Entrada: ${horario}`}</p>
        </div>
      );
    }
    return (
      <div className="flex flex-col">
        <p id="teste" className="text-center text-white m-0 btn-game">{`Minutos pagante: ${numberOne}/${numberTwo} `}</p>
        <p id="teste" className="text-center text-white m-0 btn-game">{`Entrada: ${horario}`}</p>
      </div>
    );
  };

  return (
    <div
      className="flex flex-wrap justify-center bg-black"
    >
      { jogos.itensOne ? (
        jogos.itensOne?.map((e, i) => (
          <button
            id="minutosvip"
            onMouseEnter={ () => setImagemAtiva(i) }
            onMouseLeave={ () => setImagemAtiva(null) }
            onClick={ () => {
              // fbq('track', 'ClickJogo');
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'botao_clicado',
                categoria: 'jogo',
                nome: e, // ou qualquer outra informação relevante
              });
              window.open(linkSite, '_blank');
            } }
            className="lg:w-40 w-28 m-2 mb-8 btn-game"
            key={ i }
          >
            <img
              style={ { transitionDuration: '0.5s' } }
              className="lg:w-40 lg:h-40 w-28 h-28 hover:scale-105
                    transform transition-transform btn-game"
              src={ imagemAtiva === i ? jogarImg : imagesTwoo[i] }
              alt={ e }
              id="teste"
            />
            {
              imagesTwoo[i] ? teste(e.number) : ''
            }
            {
              imagesTwoo[i] ? testeTwo(e.numberOne, e.numberTwo) : ''
            }
            <div className="flex flex-col" />
          </button>
        ))
      ) : (<CircularProgress />)}
      <p className="text-gray-800 text-justify m-3">

        AVISO IMPORTANTE: As dicas de horários oferecidas neste site são baseadas em nossa análise e experiência. Embora possam ajudá-lo a aumentar suas chances de sucesso, é importante lembrar que os jogos de azar envolvem riscos e nunca há garantias de vitória. Lembre-se sempre de jogar com responsabilidade, definir limites para suas apostas e nunca excedê-los. O jogo é destinado a ser uma forma de entretenimento e nunca deve ser visto como uma fonte de renda confiável. Ao Clicar em nossos jogos vocês serão encaminhado para nossas salas de sinais gratuitas, temos salas de sinais do fortune tigers, sala de sinais do mines, sala de sinais da roleta, sala de sinais do fortune ox, fortune mouse, sala de sinais do fortune rabbit, sala de sinais do the dog house.
      </p>
    </div>
  );
}
